<template>
	<v-content class="booking">

    <div class="mt-8 mb-8 text-center">
      <v-icon x-large class="maincolor">far fa-check-square</v-icon>
    </div>
    <h1 class="my-8 text-center fontsize18 font-weight-bold">予約の申し込みが完了しました。</h1>
		
    <div class="px-4">
      <v-divider class="mb-5"></v-divider>

      <h2 class="fontsize13 font-weight-bold mb-1">予約内容</h2>

      <v-row>
        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">予約店舗</v-col>
        <v-col cols="9" class="fontsize13">カフェリンク 目黒店</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">ご希望コース</v-col>
        <v-col cols="9" class="fontsize13">パスタ・ピザコース+2時間飲み放題 3,800円</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">予約希望日</v-col>
        <v-col cols="9" class="fontsize13">12月25日(水)</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">時間</v-col>
        <v-col cols="9" class="fontsize13">19:30〜</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">予約人数</v-col>
        <v-col cols="9" class="fontsize13">4名</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">ご要望</v-col>
        <v-col cols="9" class="fontsize13">友人の誕生日なので、食後のデザートを持ってきてもらうタイミングでサプライズミュージックをかけていただけますか。</v-col>
        
      </v-row>

      <v-divider class="mt-3 mb-5"></v-divider>

      <h2 class="fontsize13 font-weight-bold mb-1">お客様情報</h2>
      
      <v-row>
        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">氏名</v-col>
        <v-col cols="9" class="fontsize13">目黒 花子</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">メールアドレス</v-col>
        <v-col cols="9" class="fontsize13">example@abcdefg.co.jp</v-col>

        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">電話番号</v-col>
        <v-col cols="9" class="fontsize13">09012345678</v-col>
      </v-row>

      <v-divider class="mt-3 mb-5"></v-divider>

      <!-- 注意事項 -->
      <v-row>
        <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D; line-height:19.5px;">注意事項</v-col>
        <v-col cols="9" class="fontsize13">※当日のご予約はお電話にて承ります。</v-col>
      </v-row>
      
      <v-divider class="mt-3 mb-10"></v-divider>
      
    </div>

    <div class="px-4 mb-10 text-center">
      <v-btn rounded class="maincolor-bg default_button2 title py-6" to="/top-a">アプリトップに戻る</v-btn>
    </div>

	</v-content>
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {
	}
}
</script>

<style scoped>

</style>